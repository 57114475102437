.online-training {
  padding: 20px;
  font-family: Arial, sans-serif; /* Optional: Specify font-family for consistency */
  text-align: left; /* Ensures all text and headings are left-aligned */
}

.online-training h1 {
  color: orange; /* Heading color */
  margin-bottom: 20px;
}

.online-training h2 {
  color: orange; /* Heading color */
  margin-bottom: 10px;
}

.online-training p {
  color: black; /* Text color */
  margin-bottom: 20px;
  line-height: 1.6; /* Adjust line height for readability */
}

.online-training ul {
  list-style-type: none; /* Remove default bullets */
  padding-left: 20px;
  color: black; /* Text color */
}

.online-training li {
  margin-bottom: 10px;
  position: relative;
}

.online-training li::before {
  content: '•'; /* Use bullet symbol */
  color: orange; /* Bullet color */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em; /* Position bullet outside the list item */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .online-training {
    padding: 10px; /* Adjust padding for smaller devices */
  }

  .online-training h1 {
    font-size: 1.5em; /* Adjust heading size for mobile */
  }

  .online-training p {
    font-size: 0.9em; /* Adjust text size for mobile */
  }
}
