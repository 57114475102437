.footer {
  background-color: white;
  padding: 20px 0;
  color: #333;
  font-family: Arial, sans-serif;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 250px;
}

.logo1 {
  width: 150px;
  margin-bottom: 10px;
}

.footer-section h4 {
  color: #ff6600;
  margin-bottom: 10px;
}

.footer-section p,
.footer-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: black;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: black;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-section ul li i {
  margin-right: 10px;
  color: black;
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
  color: black;
}

/* Media Queries */

/* Tablet styles */
@media (max-width: 768px) {
  .footer-container {
    padding: 0 10px;
  }

  .footer-section {
    min-width: 200px;
    margin: 10px 0;
  }

  .logo1 {
    width: 120px;
    margin-bottom: 10px;
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    min-width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .footer-bottom {
    padding: 10px 0;
    border-top: none;
  }
}
