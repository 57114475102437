/* src/pages/RouteMap.css */

.route-map-container {
  max-width: 800px; /* Limits width for larger screens */
  margin: 0 auto; /* Centers the container horizontally */
  padding: 20px; /* Adds padding inside the container */
  text-align: center; /* Centers content within the container */
}

.route-map-container h2 {
  margin-bottom: 20px; /* Adds space below the heading */
  color: #333; /* Dark text color */
  font-size: 1.5em; /* Larger font size */
}

.route-map-container iframe {
  width: 100%; /* Ensures iframe fills container width */
  height: 450px; /* Sets a fixed height for the map */
  border: 0; /* Removes iframe border */
}

.route-map-container p {
  margin: 10px 0; /* Adds spacing around paragraphs */
  color: black; /* Text color */
  line-height: 1.6; /* Adjusts line height for readability */
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .route-map-container {
      padding: 10px; /* Decrease padding for smaller screens */
  }

  .route-map-container h2 {
      font-size: 1.2em; /* Decrease font size for headings */
  }

  .route-map-container iframe {
      height: 300px; /* Adjust iframe height for smaller screens */
  }
}
