.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.carousel-container {
  align-self: flex-start;
  width: 100%;
  max-width: 700px;
  margin-left: 100px;
}

.main-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
}

.intro-text {
  text-align: center;
  margin: 20px 0;
}

.intro-text h2 {
  font-size: 24px;
  color: #ff5722; /* Orange color */
}

.intro-text h3 {
  font-size: 20px;
  color: #000; /* Black color */
}

.course-details {
  width: 100%;
  text-align: center;
}

.course-details h2 {
  font-size: 24px;
  margin: 20px 0;
  color: black; /* Orange color */
}

.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.detail-item {
  max-width: 300px;
  text-align: center;
}

.detail-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.detail-item h3 {
  font-size: 18px;
  color: #ff5722; /* Orange color */
}

.detail-item p {
  font-size: 14px;
  color: #000; /* Black color */
}

.training-process {
  width: 100%;
  text-align: center;
  margin: 40px 0;
}

.training-process h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ff5722; /* Orange color */
}

.training-process img {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  display: block;
}

.course-list {
  width: 100%;
  text-align: center;
  margin: 40px 0;
}

.course-list h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: black; /* Orange color */
}

.course-column {
  display: inline-block;
  vertical-align: top;
  width: 30%;
  padding: 0 10px;
}

.course-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.course-column li {
  font-size: 16px;
  color: orange; /* Black color */
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.course-column li::before {
  content: '\2022';
  color: #ff5722; /* Orange color */
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
}

.separator {
  height: 2px;
  width: 100%;
  background-color: #ff5722; /* Orange color */
  margin: 20px 0;
}

/* Media Queries */

/* Tablet styles */
@media (max-width: 768px) {
  .carousel-container {
    margin-left: 0;
    max-width: 100%;
  }

  .main-image {
    height: 300px;
  }

  .intro-text h2 {
    font-size: 20px;
  }

  .intro-text h3 {
    font-size: 18px;
  }

  .course-details h2 {
    font-size: 20px;
  }

  .detail-item {
    max-width: 45%;
  }

  .training-process h2 {
    font-size: 20px;
  }

  .training-process img {
    width: 90%;
  }

  .course-list h2 {
    font-size: 20px;
  }

  .course-column {
    width: 45%;
    margin-bottom: 20px;
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .carousel-container {
    margin-left: 0;
    max-width: 100%;
  }

  .main-image {
    height: 250px;
    width :450px
  }

  .intro-text h2 {
    font-size: 18px;
  }

  .intro-text h3 {
    font-size: 16px;
  }

  .course-details h2 {
    font-size: 18px;
  }

  .detail-item {
    max-width: 90%;
  }

  .training-process h2 {
    font-size: 18px;
  }

  .training-process img {
    width: 100%;
  }

  .course-list h2 {
    font-size: 18px;
  }

  .course-column {
    width: 90%;
    margin-bottom: 20px;
  }

  .course-column li {
    font-size: 14px;
    padding-left: 15px;
  }

  .course-column li::before {
    font-size: 16px;
    left: 5px;
  }
}
