.course-fees-duration {
  padding: 20px;
  font-family: Arial, sans-serif; /* Optional: Specify font-family for consistency */
}

.course-fees-duration h2 {
  text-align: center;
  margin-bottom: 20px;
}

.course-fees-duration table {
  width: 100%;
  border-collapse: collapse;
  margin: auto;
}

.course-fees-duration th,
.course-fees-duration td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.course-fees-duration th {
  background-color: #f2f2f2;
}

.course-fees-duration tr:nth-child(even) {
  background-color: #f9f9f9;
}

.course-fees-duration tr:hover {
  background-color: #f1f1f1;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .course-fees-duration {
    padding: 10px; /* Adjust padding for smaller devices */
  }

  .course-fees-duration h2 {
    font-size: 1.5em; /* Adjust heading size for mobile */
  }

  .course-fees-duration table {
    font-size: 0.9em; /* Adjust table text size for mobile */
  }

  .course-fees-duration th,
  .course-fees-duration td {
    padding: 6px; /* Adjust table cell padding for mobile */
  }
}
