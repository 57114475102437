/* src/pages/WebsiteDevelopment.css */

.website-development {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left; /* Ensures all text and headings are left-aligned */
}

.website-development h1 {
  color: orange; /* Heading color */
  margin-bottom: 20px;
}

.website-development h2 {
  color: orange; /* Heading color */
  margin-bottom: 10px;
}

.website-development p,
.website-development ul {
  color: black; /* Text color */
  margin-bottom: 20px;
  line-height: 1.6;
}

.website-development ul {
  list-style-type: none; /* Removes default list bullets */
}

.website-development ul li::before {
  content: "\2022"; /* Unicode for bullet (•) */
  color: orange; /* Bullet color */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .website-development {
      padding: 10px; /* Decrease padding for smaller screens */
  }

  .website-development h1 {
      font-size: 24px; /* Decrease heading font size for smaller screens */
  }

  .website-development h2 {
      font-size: 20px; /* Decrease subheading font size for smaller screens */
  }

  .website-development p,
  .website-development ul {
      font-size: 16px; /* Decrease text font size for smaller screens */
  }
}
