/* src/pages/Reviews.css */

.reviews-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  background-color: #f8f9fa; /* Light background color */
  padding: 20px; /* Add padding for spacing */
  text-align: center; /* Center align text */
}

.reviews-message {
  font-size: 1.5em; /* Larger font size */
  color: #333; /* Dark text color */
  margin-top: 20px; /* Top margin */
  line-height: 1.4; /* Adjust line height for readability */
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .reviews-message {
      font-size: 1.2em; /* Decrease font size */
  }
}
