.cloud {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left;
}

.cloud h1 {
  color: orange;
  margin-bottom: 20px;
}

.cloud h2 {
  color: orange;
  margin-bottom: 10px;
}

.cloud p,
.cloud ul {
  color: black;
  margin-bottom: 20px;
  line-height: 1.6;
}

.cloud ul {
  list-style-type: none;
}

.cloud ul li::before {
  content: "\2022";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .cloud {
    padding: 10px; /* Adjust padding for smaller devices */
  }

  .cloud h1 {
    font-size: 1.5em; /* Adjust heading size for mobile */
  }

  .cloud h2 {
    font-size: 1.2em; /* Adjust subheading size for mobile */
  }

  .cloud p,
  .cloud ul {
    font-size: 0.9em; /* Adjust text size for mobile */
  }
}
