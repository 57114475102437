.about-us {
  padding: 20px;
  text-align: left;
  font-family: Arial, sans-serif;
}

.about-us h2 {
  color: orange;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.5em;
}

.about-us p {
  color: black;
  margin-bottom: 20px;
  font-size: 1em;
  line-height: 1.5;
}

.about-us ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.about-us li {
  color: black;
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 1.5;
}

.about-us h2:first-of-type {
  margin-top: 0;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .about-us {
    padding: 10px;
  }

  .about-us h2 {
    font-size: 1.3em;
  }

  .about-us p,
  .about-us li {
    font-size: 0.9em;
  }
}
