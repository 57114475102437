.classroom-training {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left;
}

.classroom-training h1 {
  color: orange;
  margin-bottom: 20px;
}

.classroom-training p {
  color: black;
  margin-bottom: 20px;
  line-height: 1.6;
}

.classroom-training h2 {
  color: orange;
  margin-bottom: 10px;
}

.classroom-training ul {
  list-style-type: none;
  padding-left: 20px;
  color: black;
}

.classroom-training li {
  margin-bottom: 10px;
  position: relative;
}

.classroom-training li::before {
  content: '•';
  color: orange;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .classroom-training {
    padding: 10px; /* Adjust padding for smaller devices */
  }

  .classroom-training h1 {
    font-size: 1.5em; /* Adjust heading size for mobile */
  }

  .classroom-training h2 {
    font-size: 1.2em; /* Adjust subheading size for mobile */
  }

  .classroom-training p,
  .classroom-training ul {
    font-size: 0.9em; /* Adjust text size for mobile */
  }
}
