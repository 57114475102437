/* src/pages/Python.css */

.python {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left; /* Ensures all text and headings are left-aligned */
}

.python h1 {
  color: orange; /* Heading color */
  margin-bottom: 20px;
}

.python h2 {
  color: orange; /* Heading color */
  margin-bottom: 10px;
}

.python p,
.python ul {
  color: black; /* Text color */
  margin-bottom: 20px;
  line-height: 1.6;
}

.python ul {
  list-style-type: none; /* Removes default list bullets */
  padding-left: 20px; /* Indent list items */
}

.python ul li::before {
  content: "\2022"; /* Unicode for bullet (•) */
  color: orange; /* Bullet color */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .python {
      padding: 10px; /* Decrease padding */
  }

  .python h1 {
      font-size: 1.5em; /* Adjust font size */
  }

  .python h2 {
      font-size: 1.2em; /* Adjust font size */
  }

  .python ul {
      padding-left: 10px; /* Adjust list padding */
  }
}
