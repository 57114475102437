.college-projects {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left;
}

.college-projects h1 {
  color: orange;
  margin-bottom: 20px;
}

.college-projects h2 {
  color: orange;
  margin-bottom: 10px;
}

.college-projects p,
.college-projects ul {
  color: black;
  margin-bottom: 20px;
  line-height: 1.6;
}

.college-projects ul {
  list-style-type: none;
}

.college-projects ul li::before {
  content: "\2022";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .college-projects {
    padding: 10px; /* Adjust padding for smaller devices */
  }

  .college-projects h1 {
    font-size: 1.5em; /* Adjust heading size for mobile */
  }

  .college-projects h2 {
    font-size: 1.2em; /* Adjust subheading size for mobile */
  }

  .college-projects p,
  .college-projects ul {
    font-size: 0.9em; /* Adjust text size for mobile */
  }
}
