/* Reset default margin and padding */
body, h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

/* Basic styling for the entire app */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
}

/* Styling for the header */
.header {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
}

.header h1 {
  font-size: 2.5rem;
}

/* Styling for the navigation bar */
.navbar {
  background-color: #444;
  padding: 10px 0;
}

.navbar ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.navbar li {
  margin: 0 10px;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
}

.navbar a:hover {
  text-decoration: underline;
}

/* Styling for the main content area */
.container {
  margin-top: 20px;
  padding: 0 20px; /* Added padding to provide space around content */
}

/* Styling for the contact form */
.contact-form {
  margin-top: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width */
}

.contact-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .header h1 {
    font-size: 2rem; /* Decrease header font size for smaller screens */
  }

  .navbar {
    padding: 5px 0; /* Decrease navbar padding for smaller screens */
  }

  .navbar li {
    margin: 0 5px; /* Decrease margin around navbar items for smaller screens */
  }

  .container {
    margin-top: 10px; /* Decrease top margin for smaller screens */
    padding: 0 10px; /* Decrease padding around content for smaller screens */
  }

  .contact-form input,
  .contact-form textarea {
    padding: 8px; /* Decrease input and textarea padding for smaller screens */
    margin-bottom: 8px; /* Decrease bottom margin for smaller screens */
  }

  .contact-form button {
    padding: 8px 16px; /* Decrease button padding for smaller screens */
  }
}
