.data-analytics {
  padding: 20px;
  font-family: Arial, sans-serif; /* Optional: Specify font-family for consistency */
  text-align: left; /* Ensures all text and headings are left-aligned */
}

.data-analytics h1 {
  color: orange; /* Heading color */
  margin-bottom: 20px;
}

.data-analytics h2 {
  color: orange; /* Heading color */
  margin-bottom: 10px;
}

.data-analytics p,
.data-analytics ul {
  color: black; /* Text color */
  margin-bottom: 20px;
  line-height: 1.6;
}

.data-analytics ul {
  list-style-type: none; /* Removes default list bullets */
}

.data-analytics ul li::before {
  content: "\2022"; /* Unicode for bullet (•) */
  color: orange; /* Bullet color */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .data-analytics {
    padding: 10px; /* Adjust padding for smaller devices */
  }

  .data-analytics h1 {
    font-size: 1.5em; /* Adjust heading size for mobile */
  }

  .data-analytics h2 {
    font-size: 1.2em; /* Adjust sub-heading size for mobile */
  }

  .data-analytics p,
  .data-analytics ul {
    font-size: 0.9em; /* Adjust text size for mobile */
  }
}
