.interview-guidance {
  padding: 20px;
  font-family: Arial, sans-serif; /* Optional: Specify font-family for consistency */
  text-align: left; /* Ensures all text and headings are left-aligned */
}

.interview-guidance h1 {
  color: orange; /* Heading color */
  margin-bottom: 20px;
}

.interview-guidance p {
  color: black; /* Text color */
  margin-bottom: 20px;
  line-height: 1.6; /* Adjust line height for readability */
}

.interview-guidance strong {
  font-weight: bold; /* Make strong tags bold */
  color: blue; /* Set color for strong tags */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .interview-guidance {
    padding: 10px; /* Adjust padding for smaller devices */
  }

  .interview-guidance h1 {
    font-size: 1.5em; /* Adjust heading size for mobile */
  }

  .interview-guidance p {
    font-size: 0.9em; /* Adjust text size for mobile */
  }
}
