/* src/pages/TrainersProfile.css */

.trainers-profile {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left; /* Ensures all text and headings are left-aligned */
}

.trainers-profile h2 {
  color: orange; /* Heading color */
  margin-bottom: 20px;
  font-size: 24px; /* Adjust heading font size */
  font-weight: bold; /* Adjust heading font weight */
}

.trainer {
  margin-bottom: 20px;
}

.trainer h3 {
  color: orange; /* Heading color */
  margin-bottom: 10px;
  font-size: 20px; /* Adjust trainer name font size */
  font-weight: bold; /* Adjust trainer name font weight */
}

.trainer ul {
  margin: 5px 0;
  padding-left: 20px; /* Add left padding for bullet points */
}

.trainer ul li {
  color: black; /* Bullet points color */
  font-size: 16px; /* Adjust bullet points font size */
}

.trainer p {
  color: black; /* Text color */
  font-size: 16px; /* Adjust paragraph text font size */
  line-height: 1.6; /* Adjust line height for better readability */
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .trainers-profile {
      padding: 10px; /* Decrease padding for smaller screens */
  }

  .trainers-profile h2 {
      font-size: 20px; /* Decrease heading font size for smaller screens */
  }

  .trainer h3 {
      font-size: 18px; /* Decrease trainer name font size for smaller screens */
  }

  .trainer ul li {
      font-size: 14px; /* Decrease bullet points font size for smaller screens */
  }

  .trainer p {
      font-size: 14px; /* Decrease paragraph text font size for smaller screens */
  }
}
