/* src/pages/Scala.css */

.scala {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left; /* Ensures all text and headings are left-aligned */
}

.scala h1 {
  color: orange; /* Heading color */
  margin-bottom: 20px;
}

.scala h2 {
  color: orange; /* Heading color */
  margin-bottom: 10px;
}

.scala p,
.scala ul {
  color: black; /* Text color */
  margin-bottom: 20px;
  line-height: 1.6;
}

.scala ul {
  list-style-type: none; /* Removes default list bullets */
}

.scala ul li::before {
  content: "\2022"; /* Unicode for bullet (•) */
  color: orange; /* Bullet color */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .scala {
      padding: 10px; /* Decrease padding for smaller screens */
  }

  .scala h1 {
      font-size: 1.5em; /* Increase font size for headings */
  }

  .scala h2 {
      font-size: 1.2em; /* Adjust font size for subheadings */
  }
}
