.additional-content {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h2 {
    font-size: 24px;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .section h3 {
    font-size: 20px;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .section p {
    margin-bottom: 10px;
  }
  
  .section ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }
  
  .section ul li {
    margin-bottom: 8px;
    line-height: 1.6;
  }
  
  .section img.institute-image {
    width: 100%; /* Adjust image width as needed */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .course-list {
    color: #333;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }
  
  .course-list li::before {
    content: "\2022"; /* Bullet character (•) */
    color: orange; /* Bullet color */
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    margin-right: 5px; /* Adjust margin between bullet and text */
  }
  