.ms-office {
  padding: 20px;
  font-family: Arial, sans-serif; /* Optional: Specify font-family for consistency */
  text-align: left; /* Ensures all text and headings are left-aligned */
}

.ms-office h1 {
  color: orange; /* Heading color */
  margin-bottom: 20px;
}

.ms-office h2 {
  color: orange; /* Heading color */
  margin-bottom: 10px;
}

.ms-office p,
.ms-office ul {
  color: black; /* Text color */
  margin-bottom: 20px;
  line-height: 1.6; /* Adjust line height for readability */
}

.ms-office ul {
  list-style-type: none; /* Removes default list bullets */
}

.ms-office ul li::before {
  content: "\2022"; /* Unicode for bullet (•) */
  color: orange; /* Bullet color */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .ms-office {
    padding: 10px; /* Adjust padding for smaller devices */
  }

  .ms-office h1 {
    font-size: 1.5em; /* Adjust heading size for mobile */
  }

  .ms-office p {
    font-size: 0.9em; /* Adjust text size for mobile */
  }
}
