.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-logo {
  width: 150px; /* Adjusted size for better fit */
  height: 150px; /* Adjusted size for better fit */
  transition: transform 0.3s ease;
}

.whatsapp-logo:hover {
  transform: scale(1.1);
}

.whatsapp-button .tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  left: -50px; /* Adjusted position to the left side of the logo */
  bottom: 50px; /* Adjusted vertical position for better visibility */
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap; /* Prevent text from wrapping */
}

.whatsapp-button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Media Queries */

@media (max-width: 768px) {
  .whatsapp-button {
    bottom: 10px;
    right: 10px;
  }

  .whatsapp-logo {
    width: 120px; /* Adjusted size for smaller screens */
    height: 120px; /* Adjusted size for smaller screens */
  }

  .whatsapp-button .tooltip {
    left: -40px; /* Adjusted position for smaller screens */
    bottom: 40px; /* Adjusted position for smaller screens */
  }
}
