.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: black;
  border-bottom: 1px solid #ddd;
}

.logo {
  height: 100px;
  width: 180px;
}

.contact-info1 {
  text-align: center; /* Center alignment */
}

.contact-info1 p {
  margin: 0;
  font-size: 14px;
  color: #ddd;
}

/* Media Queries */

/* Tablet styles */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    padding: 15px 10px;
    text-align: center;
  }

  .logo {
    height: 50px;
    width: 120px;
    margin-bottom: 10px;
  }

  .contact-info1 {
    text-align: center; /* Center alignment */
  }

  .contact-info1 p {
    font-size: 12px;
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .header {
    flex-direction: column;
    padding: 10px 5px;
    text-align: center;
  }

  .logo {
    height: 70px;
    width: 130px;
    margin-bottom: 5px;
  }

  .contact-info1 {
    text-align: center; /* Center alignment */
  }

  .contact-info1 p {
    font-size: 10px;
  }
}
