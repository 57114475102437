.android-development {
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left;
}

.android-development h1 {
  color: orange;
  margin-bottom: 20px;
}

.android-development h2 {
  color: orange;
  margin-bottom: 10px;
}

.android-development p,
.android-development ul {
  color: black;
  margin-bottom: 20px;
  line-height: 1.6;
}

.android-development ul {
  list-style-type: none;
}

.android-development ul li::before {
  content: "\2022"; /* Unicode for bullet (•) */
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .android-development {
    padding: 10px;
  }

  .android-development h1 {
    font-size: 1.5em; /* Adjust heading size for mobile */
  }

  .android-development h2 {
    font-size: 1.2em; /* Adjust subheading size for mobile */
  }

  .android-development p,
  .android-development ul {
    font-size: 0.9em; /* Adjust text size for mobile */
  }
}
