/* FooterOrangeLineOnly.css */

.footer1 {
  padding: 20px 0;
  color: #333;
  font-family: Arial, sans-serif;
}

.orange-line {
  height: 2px;
  background-color: #ff6600;
  margin-bottom: 20px;
}

.contact-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.red-text {
  color: red; /* Red color */
  margin-right: 10px;
}

.blue-text {
  color: blue; /* Blue color */
  margin-left: 10px;
}

/* Media Queries */

/* Tablet styles */
@media (max-width: 768px) {
  .footer1 {
      padding: 15px 0;
  }

  .contact-content {
      flex-direction: column;
      margin: 0 10px;
      text-align: center;
  }

  .red-text, .blue-text {
      margin: 5px 0;
  }
}

/* Mobile styles */
@media (max-width: 480px) {
  .footer1 {
      padding: 10px 0;
  }

  .orange-line {
      margin-bottom: 10px;
  }

  .contact-content {
      flex-direction: column;
      margin: 0 5px;
      text-align: center;
  }

  .red-text, .blue-text {
      margin: 5px 0;
  }
}
