.navbar {
  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  z-index: 1000;
}

.navbar-toggle {
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.navbar-links {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin-right: 50px;
}

.navbar-links li {
  position: relative;
  margin: 0 15px;
}

.navbar-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: black;
  min-width: 200px;
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #333;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #333;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-links li {
    margin: 10px 0;
    text-align: center;
  }

  .dropdown-content {
    position: static;
    background-color: #444;
    width: 100%;
  }
}

/* Ensure navbar appears normally on larger screens */
@media (min-width: 769px) {
  .navbar-links {
    display: flex;
  }

  .navbar-toggle {
    display: none;
  }
}



